import { ReactElement } from "react"


export enum ButtonVariant {
    PRIMARY = 'btn-primary',
    SECONDRY = 'btn-secondary',
    TERTIARY = 'btn-tertiary',
    LINK = 'btn-link'
}

export enum ButtonSize {
    LARGE = 'btn-large',
    MEDIUM = 'btn-medium',
    SMALL = 'btn-small'
}

export type ButtonInput = {
    id?: string;
    variant?: ButtonVariant,
    size?: ButtonSize,
    type?: "submit" | "button" | "reset" | undefined,
    disabled?: boolean,
    className?: string,
    onClick: (e?: any) => void,
    children: ReactElement | string
}