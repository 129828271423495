import { useTranslation } from "react-i18next";
import welcome_image from "../../assets/welcome_image.webp";
import { Button } from "../../components/Button";
import { ButtonSize, ButtonVariant } from "../../components/Button/types";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTracking } from "../../hooks/useTracking";

export function Welcome({ next }: { next: Dispatch<SetStateAction<number>> }) {

    const { t } = useTranslation("welcome");

    const { productLoadEventTracking } = useTracking();

    useEffect(() => productLoadEventTracking("quickCheck"), [])

    return (
        <div className="md:grid md:grid-cols-5 md:gap-8 p-5 px-5 sm:px-16 max-w-screen-lg">
            <div className="flex justify-center sm:justify-start p-2 sm:p-0 md:col-span-2 mb-5 md:mb-0">
                <img className=" sm:w-5/12 md:w-fit w-64 h-64 sm:h-fit" src={welcome_image} />
            </div>
            <div className="flex flex-col col-span-3 text-white md:text-xl md:pr-12 justify-between">
                <div>
                    <h1 className="text-[30px] md:text-[54px] leading-none font-bold mb-6">{t("title")}</h1>
                    <div className="mb-4 ">{t("descriptionPt1")}</div>
                    <div className="mb-4">{t("descriptionPt2")}</div>
                </div>
                <div className="flex md:w-1/2 mt-6 md:mt-6">
                    <Button id="start-quick-check" variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} onClick={next} >{t("buttonText")}</Button>
                </div>
            </div>
        </div>
    );
}
